import React from 'react';
import { Modal, Form, Button, Input } from 'antd';
import UserSelect from '@/components/user-select';

export default class VideoNotificationDetails extends React.Component {
  formRef = React.createRef();

  render() {
    const { videoNotificationList, onCancel, dispatch } = this.props;

    return (
      <Modal
        visible
        width={600}
        title="Subscribe Video Notifications"
        closable={false}
        onCancel={onCancel}
        footer={
          <>
            <Button
              type="text"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => {
                this.formRef.current && this.formRef.current.submit();
              }}
            >
              Subscribe
            </Button>
          </>
        }
      >
        <Form
          labelAlign="left"
          labelCol={{ span: 4 }}
          ref={this.formRef}
          onFinish={(values) => {
            dispatch({
              type: 'videoNotificationModel/putMediaVideoNotificationsSubscribeUser',
              payload: values,
              callback: onCancel,
            });
          }}
        >
          <Form.Item
            required
            name="username"
            label="Username"
          >
            <UserSelect
              request="getUsersVideoNotificationList"
              onChange={(value) => {
                const listItem = videoNotificationList.find((item) => item.username === value);
                this.formRef.current.setFieldValue('subscribed', listItem && listItem.subscribed);
              }}
            />
          </Form.Item>
          <Form.Item
            name="subscribed"
            label="Subscribed"
          >
            <Input disabled />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}
