export const CEC = '/cec';
export const CECDOCS = '/cecdocs';

export const ROUTES = {
  HOME: `${CEC}/home`,
  ERROR: `${CEC}/error`,
  DOC_VIDEO: `${CEC}/doc_video`,
  // Lab Docs
  DOCLAB_CONTENT: `${CEC}/doc_lab/content`,
  // MCP Docs
  DOCMCP: `${CEC}/doc_mcp`,
  // Videos
  VIDEO: `${CEC}/video`,
  // Instructor - Classroom Management
  INSTRUCTOR_CLASSROOM: `${CEC}/instructor/classroom`,
  // Instructor - Student Management
  INSTRUCTOR_STUDENT: `${CEC}/instructor/student`,
  // Instructor - Dashboard
  INSTRUCTOR_DASHBOARD: `${CEC}/instructor/dashboard`,
  // Admin - Content Management
  ADMIN_CONTENT: `${CEC}/admin/content`,
  ADMIN_SUBCATEGORY: `${CEC}/admin/subcategory`,
  ADMIN_DOCMCP: `${CEC}/admin/doc_mcp`,
  // Admin - Sercurity
  ADMIN_ACTIVESESSION: `${CEC}/admin/active_session`,
  ADMIN_USERACCOUNT: `${CEC}/admin/user_account`,
  ADMIN_PERMISSION: `${CEC}/admin/permission`,
  ADMIN_BLACKLIST: `${CEC}/admin/blacklist`,
  // Admin - Domain Management
  ADMIN_DOMAIN: `${CEC}/admin/domain`,
  ADMIN_USERDOMAIN: `${CEC}/admin/user_domain`,
  // Admin - Site Management
  ADMIN_EULA: `${CEC}/admin/eula`,
  ADMIN_FILEUPLOADEULA: `${CEC}/admin/file_upload_eula`,
  ADMIN_EVENT: `${CEC}/admin/event`,
  ADMIN_LANDINGPAGE: `${CEC}/admin/landing_page`,
  ADMIN_SWAGGERFILE: `${CEC}/admin/swagger_file`,
  ADMIN_DEVICEIMAGE: `${CEC}/admin/device_image`,
  // Admin - Schedule Management
  ADMIN_SCHEDULE: `${CEC}/admin/schedule`,
  // Admin - Totara Management
  ADMIN_TOTARAAPI: `${CECDOCS}/self-paced-learning`,
  // Admin - Portal Management
  ADMIN_PORTALAPI: `${CECDOCS}/admin`,
  // Admin - Report Issue Management
  ADMIN_REPORTISSUE: `${CEC}/admin/report_issue`,
  // Lab
  LAB: `${CEC}/lab`,
  LAB_SCHEDULE: `${CEC}/lab/schedule`,
  LAB_SCHEDULE_NEW: `${CEC}/lab/schedule?new`,
  LAB_USERFILE: `${CEC}/lab/user_file`,
  LAB_INTERACTION: `${CEC}/lab/interaction`,
  LAB_TERMINAL: `${CEC}/lab/terminal`, // Single pages (no header and footer)
  LAB_CRAFT: `${CEC}/lab/craft`, // Single pages (no header and footer)
  // Analytics - Generate Report
  ANALYTICS_GENERATE_REPORT: `${CEC}/analytics/generate_report`,
  // Media - Documentation Video
  MEDIA_DOC_VIDEO: `${CEC}/media/doc_video`,
  MEDIA_VIDEO_NOTIFICATION: `${CEC}/media/video_notification`,
  // User Profile
  USER: `${CEC}/user`,
};

export const SINGLE_PAGES = [ROUTES.LAB_TERMINAL, ROUTES.LAB_CRAFT];

export const PAGES_WITHOUT_REDIRECT = [
  new RegExp(`^${CEC}$`),
  new RegExp(`^${ROUTES.HOME}$`),
  new RegExp(`^${ROUTES.ERROR}$`),
  new RegExp(`^${ROUTES.DOC_VIDEO}/(.+)`),
];

export const TOOLBAR_ICONS = {
  LAB_DOCS: 'Lab Docs',
  MCP_DOCS: 'MCP Docs',
  VIDEOS: 'Videos',
  LABS: 'Labs',
  ANALYTICS: 'Analytics',
  MEDIA: 'Media',
  INSTRUCTOR: 'Instructor',
  ADMIN: 'Admin',
  TRAININGADMIN: 'Training Admin',
};

export const DEVICE_IMAGE_PATH = '/device-images';
export const SWAGGER_FILE_PATH = '/swagger-files';

export const ROLE_LIST = [
  'Super Admin',
  'Admin',
  'Content Admin',
  'Media Admin',
  'Analytics Admin',
  'Analytics User',
  'Instructor',
  'Learning Admin',
  'Training Admin',
  'Student',
  'User',
];

export const DOMAIN_LIST = ['COMMUNITY', 'MANAGED', 'TRAINING', 'DEMO'];

export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export const LAB_STATUS_LIST = [
  'Scheduled',
  'Pausing before restart',
  'Restart required',
  'Starting',
  'Configuring',
  'Active',
  'Deleting',
  'Stopping',
  'Ended',
  'Ended with error',
];

export const NC_STATUS_LIST = [
  'IDLE',
  'ACTIVE',
  'ERROR',
  'STARTING',
  'STOPPING',
  'CONFIGURING',
  'UNAVAILABLE',
];

export const BOOKING_STATUS_LIST = [
  'IDLE',
  'CREATED',
  'READY_TO_REQUEST_LAB',
  'REQUEST_LAB_SENT',
  'REQUEST_LAB_SUCCESS',
  'REQUEST_LAB_FAILED',
  'DELETE_LAB_SENT',
  'DELETE_LAB_SUCCESS',
  'DELETE_LAB_FAILED',
  'ADMIN_CANCEL_REQUESTED',
  'USER_CANCEL_REQUESTED',
  'ADMIN_CANCELLED',
  'USER_CANCELLED',
  'ENDED',
  'ENDED_ERROR',
];

export const CLASSROOM_STATUS_LIST = [
  'IDLE',
  'CREATED',
  'REQUEST_DATA_ERROR',
  'ACTIVE',
  'RUNNING',
  'DELETE_LAB_SENT',
  'DELETE_SUCCESS',
  'DELETE_FAILED',
  'ADMIN_CANCEL_REQUESTED',
  'INSTRUCTOR_CANCEL_REQUESTED',
  'ADMIN_CANCELLED',
  'INSTRUCTOR_CANCELLED',
  'ENDED',
  'ENDED_ERROR',
];
