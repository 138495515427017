import appModel from './appModel';
import analyticsModel from './analyticsModel';
import contentModel from './contentModel';
import classroomModel from './classroomModel';
import dashboardModel from './dashboardModel';
import studentModel from './studentModel';
import landingPageBannerModel from './landingPageBannerModel';
import deviceImageModel from './deviceImageModel';
import reportIssueModel from './reportIssueModel';
import eulaModel from './eulaModel';
import portalEventModel from './portalEventModel';
import fileUploadEulaModel from './fileUploadEulaModel';
import userModel from './userModel';
import swaggerFileModel from './swaggerFileModel';
import labModel from './labModel';
import labDomainModel from './labDomainModel';
import userLabDomainModel from './userLabDomainModel';
import permissionModel from './permissionModel';
import blacklistModel from './blacklistModel';
import fileUploadModel from './fileUploadModel';
import scheduleModel from './scheduleModel';
import videoModel from './videoModel';
import docVideoModel from './docVideoModel';
import videoNotificationModel from './videoNotificationModel';

const models = [
  appModel,
  analyticsModel,
  contentModel,
  classroomModel,
  dashboardModel,
  studentModel,
  landingPageBannerModel,
  deviceImageModel,
  reportIssueModel,
  eulaModel,
  portalEventModel,
  fileUploadEulaModel,
  userModel,
  swaggerFileModel,
  labModel,
  labDomainModel,
  userLabDomainModel,
  permissionModel,
  blacklistModel,
  fileUploadModel,
  scheduleModel,
  videoModel,
  docVideoModel,
  videoNotificationModel,
];

export default models;
