import request from '@/utils/request';
import { formatFormData } from '@/utils';
import { CEC } from '@/constants';

/**
 * Endpoints
 */
// Analytics
export const ANALYTICS_PLATFORMINFO = `${CEC}/analytics/platform-info`;
export const ANALYTICS_EVENTSMATRIX = `${CEC}/analytics/events-matrix`;
export const ANALYTICS_REPORTTYPES = `${CEC}/analytics//report-types`;
export const ANALYTICS_VISITREPORT = `${CEC}/analytics/visit-report`;
export const ANALYTICS_LABSBOOKED = `${CEC}/analytics/labs-booked`;
export const ANALYTICS_ADMIN_LABSBOOKED = `${CEC}/analytics/admin/labs-booked`;
export const ANALYTICS_ADMIN_LABSBOOKEDCOUNTS = `${CEC}/analytics/admin/labs-booked-counts`;
export const ANALYTICS_ADMIN_VIEWEDDOCUMENTSCOUNTS = `${CEC}/analytics/admin/viewed-documents-counts`;
export const ANALYTICS_ADMIN_WATCHEDVIDEOSCOUNTS = `${CEC}/analytics/admin/watched-videos-counts`;
export const ANALYTICS_ADMIN_DOCUMENTATIONVIDEOLINKCOUNTS = `${CEC}/analytics/admin/documentation-video-link-counts`;
export const ANALYTICS_ADMIN_WATCHEDDOCUMENTATIONVIDEOCOUNTS = `${CEC}/analytics/admin/watched-documentation-video-counts`;
export const ANALYTICS_ENROLLEDUSERS = `${CEC}/analytics/enrolled-users`;
export const ANALYTICS_ADMIN_ENROLLEDUSERS = `${CEC}/analytics/admin/enrolled-users`;
export const ANALYTICS_ADMIN_ANALYTICSPORTALURL = `${CEC}/analytics/admin/analytics-portal-url`;
export const ANALYTICS_NOTIFICATIONEVENT_OPENLAB = `${CEC}/analytics/notification-event/open-lab`;
export const ANALYTICS_NOTIFICATIONEVENT_WATCHVIDEO = `${CEC}/analytics/notification-event/watch-video`;
export const ANALYTICS_NOTIFICATIONEVENT_READDOC = `${CEC}/analytics/notification-event/read-doc`;
export const ANALYTICS_APITRACKER_FIELDLISTS = `${CEC}/analytics/api-tracker/field-lists`;
export const ANALYTICS_APITRACKER_ARCHIVE_COUNTS = `${CEC}/analytics/api-tracker/archive/counts`;
export const ANALYTICS_APITRACKER_COUNTS = `${CEC}/analytics/api-tracker/counts`;

// Auth
export const AUTH_LOGIN_SSO = `${CEC}/auth/login/sso`;
export const AUTH_LOGOUT = `${CEC}/auth/logout`;
export const AUTH_STUDENT_LOGIN = `${CEC}/auth/student/login`;
export const AUTH_SWITCHDOMAIN = `${CEC}/auth/switch-domain`;
export const AUTH_PROFILE = `${CEC}/auth/profile`;

// Users
export const USERS = `${CEC}/users`;
export const USERS_ADMIN_USERNAMELIST = `${CEC}/users/admin/username-list`;
export const USERS_ACTIVE = `${CEC}/users/active`;
export const USERS_INSTRUCTORLIST = `${CEC}/users/instructor-list`;
export const USERS_PROFILE = `${CEC}/users/profile`;
export const USERS_EULA_VERSION = `${CEC}/users/eula/version`;
export const USERS_AUTHORIZATION_TOOLBARICONS = `${CEC}/users/authorization/toolbar-icons`;
export const USERS_SWITCHDOMAIN = `${CEC}/users/switch-domain`;
export const USERS_ROLE = `${CEC}/users/role`;
export const USERS_ROLES = `${CEC}/users/roles`;
export const USERS_MEMBERDOMAINS = `${CEC}/users/member-domains`;
export const USERS_GENERATEAPIKEY = `${CEC}/users/generate-api-key`;
export const USERS_REPORTISSUES_ADMIN_LISTISSUES = `${CEC}/users/report-issues/admin/list-issues`;
export const USERS_REPORTISSUES_SUPPORTEDFILETYPES = `${CEC}/users/report-issues/supported-file-types`;
export const USERS_REPORTISSUES = `${CEC}/users/report-issues`;
export const USERS_REPORTISSUES_ADMIN_SENDRESPONSE = `${CEC}/users/report-issues/admin/send-response`;
export const USERS_VIDEONOTIFICATIONLIST = `${CEC}/users/video-notification-list`;

// Content Management
export const CONTENTMANAGEMENT_CATEGORIES = `${CEC}/content-management/categories`;
export const CONTENTMANAGEMENT_CATEGORIES_ADMIN = `${CEC}/content-management/categories/admin`;
export const CONTENTMANAGEMENT_SUBCATEGORIES = `${CEC}/content-management/sub-categories`;
export const CONTENTMANAGEMENT_SUBCATEGORIES_ADMIN = `${CEC}/content-management/sub-categories/admin`;
export const CONTENTMANAGEMENT_CONTENTS = `${CEC}/content-management/contents`;
export const CONTENTMANAGEMENT_CONTENTS_ADMIN = `${CEC}/content-management/contents/admin`;
export const CONTENTMANAGEMENT_MCPDOCS = `${CEC}/content-management/mcp-docs`;
export const CONTENTMANAGEMENT_MCPDOCS_ADMIN = `${CEC}/content-management/mcp-docs/admin`;

// Email
export const EMAIL_SENDNOTIFYTOBCCLIST = `${CEC}/email/send-notify-to-bcc-list`;

// Eulas
export const SITEMANAGEMENT_EULAS_LATEST = `${CEC}/site-management/eulas/latest`;
export const SITEMANAGEMENT_EULAS = `${CEC}/site-management/eulas`;

// File Upload Eulas
export const SITEMANAGEMENT_FILEUPLOADEULAS_LATEST = `${CEC}/site-management/file-upload-eulas/latest`;
export const SITEMANAGEMENT_FILEUPLOADEULAS = `${CEC}/site-management/file-upload-eulas`;

// Portal Events
export const SITEMANAGEMENT_PORTALEVENTS = `${CEC}/site-management/portal-events`;
export const SITEMANAGEMENT_PORTALEVENTS_ACTIVE = `${CEC}/site-management/portal-events/active`;

// Landing Page Banners
export const SITEMANAGEMENT_LANDINGPAGE = `${CEC}/site-management/landing-page`;
export const SITEMANAGEMENT_LANDINGPAGE_ACTIVE = `${CEC}/site-management/landing-page/active`;

// Swagger Files
export const SITEMANAGEMENT_SWAGGERFILES = `${CEC}/site-management/swagger-files`;

// Device Images
export const SITEMANAGEMENT_DEVICEIMAGES = `${CEC}/site-management/device-images`;

// Domain Blacklist
export const SECURITY_DOMAINBLACKLIST = `${CEC}/security/domain-blacklist`;

// User Permissions
export const SECURITY_USERPERMISSIONS = `${CEC}/security/user-permissions`;

// Lab Domains
export const LABDOMAINS = `${CEC}/lab-domains`;

// User Lab Domains
export const USERLABDOMAINS = `${CEC}/user-lab-domains`;

// Labs
export const LABS_BOOKINGS = `${CEC}/labs/bookings`;
export const LABS_BOOKINGS_ADMIN = `${CEC}/labs/bookings/admin`;
export const LABS_BOOKINGS_AUDIT = `${CEC}/labs/bookings/audit`;
export const LABS_BOOKINGS_CANCEL = `${CEC}/labs/bookings/cancel`;
export const LABS_BOOKINGS_ADMIN_CANCEL = `${CEC}/labs/bookings/admin/cancel`;
export const LABS_BOOKINGS_MAINTENANCEMODE = `${CEC}/labs/bookings/maintenance-mode`;
export const LABS_BOOKINGS_ADMIN_UPDATEBOOKING = `${CEC}/labs/bookings/admin/update-booking`;
export const LABS_BOOKINGS_SCHEDULED = `${CEC}/labs/bookings/scheduled`;
export const LABS_BOOKINGS_SCHEDULED_ADMIN = `${CEC}/labs/bookings/scheduled/admin`;
export const LABS_NETWORKCONFIGTYPES_ADMIN = `${CEC}/labs/networkconfigtypes/admin`;
export const LABS_VIRTUALDEVICES = `${CEC}/labs/virtualdevices`;
export const LABS_VIRTUALDEVICES_ADMIN = `${CEC}/labs/virtualdevices/admin`;
export const LABS_ACTIONS = `${CEC}/labs/actions`;
export const LABS_ACTIONS_ADMIN = `${CEC}/labs/actions/admin`;
export const LABS_ACTIONS_REQUEST = `${CEC}/labs/actions/request`;
export const LABS_ACTIONS_INVENTORY = `${CEC}/labs/actions/inventory`;
export const LABS_ACTIONS_INVENTORY_ADMIN = `${CEC}/labs/actions/inventory/admin`;
export const LABS_VIRTUALDEVICES_INTERACTIONS = `${CEC}/labs/virtualdevices/interactions`;
export const LABS_VIRTUALDEVICES_INTERACTIONS_ADMIN = `${CEC}/labs/virtualdevices/interactions/admin`;

// Media
export const MEDIA_VIDEOPORTALURL = `${CEC}/media/video-portal-url`;
export const MEDIA_ADMIN_VIDEOPORTALURL = `${CEC}/media/admin/video-portal-url`;
export const MEDIA_MEDIALIST = `${CEC}/media/media-list`;
export const MEDIA_CATEGORYLIST = `${CEC}/media/category-list`;
export const MEDIA_TAGLIST = `${CEC}/media/tag-list`;
export const MEDIA_FIXEDDFILTERS = `${CEC}/media/fixed-filters`;
export const MEDIA_DOCUMENTATION_MEDIALIST = `${CEC}/media/documentation/media-list`;
export const MEDIA_DOCUMENTATION_DOCUMENTATIONPRODUCTTYPES = `${CEC}/media/documentation/documentation-product-types`;
export const MEDIA_DOCUMENTATION_MEDIALIST_DOCUMENTATIONREPORT = `${CEC}/media/documentation/media-list/documentation-report`;
export const MEDIA_DOCUMENTATION_DOCUMENTATIONLINKS = `${CEC}/media/documentation/documentation-links`;
export const MEDIA_VIDEONOTIFICATIONS_SUBSCRIBE = `${CEC}/media/video-notifications/subscribe`;
export const MEDIA_VIDEONOTIFICATIONS_UNSUBSCRIBE = `${CEC}/media/video-notifications/unsubscribe`;
export const MEDIA_VIDEONOTIFICATIONS_LIST = `${CEC}/media/video-notifications/list`;
export const MEDIA_VIDEONOTIFICATIONS_SUBSCRIBEUSER = `${CEC}/media/video-notifications/subscribe-user`;
export const MEDIA_VIDEONOTIFICATIONS_UNSUBSCRIBEUSER = `${CEC}/media/video-notifications/unsubscribe-user`;

// Proxy
export const PROXY_COMPUTEPATH = `${CEC}/proxy/compute-path`;

// Schedule Lab
export const SCHEDULE_GETSCHEDULE = `${CEC}/schedule/get-schedule`;
export const SCHEDULE_GETSCHEDULEFORWINDOW = `${CEC}/schedule/get-schedule-for-window`;
export const SCHEDULE_VERIFYBOOKING = `${CEC}/schedule/verify-booking`;
export const SCHEDULE_SCHEDULELAB = `${CEC}/schedule/schedule-lab`;
export const SCHEDULE_SCHEDULELAB_ADMIN = `${CEC}/schedule/schedule-lab/admin`;

// Classroom
export const CLASSROOMS = `${CEC}/classrooms`;
export const CLASSROOMS_ADMIN = `${CEC}/classrooms/admin`;
export const CLASSROOMS_ACTIVE = `${CEC}/classrooms/active`;
export const CLASSROOMS_ACTIVE_ADMIN = `${CEC}/classrooms/active/admin`;
export const CLASSROOMS_CANCEL = `${CEC}/classrooms/cancel`;
export const CLASSROOMS_ADMIN_CANCEL = `${CEC}/classrooms/admin/cancel`;
export const CLASSROOMS_SCHEDULED = `${CEC}/classrooms/scheduled`;
export const CLASSROOMS_SCHEDULED_ADMIN = `${CEC}/classrooms/scheduled/admin`;
export const CLASSROOMS_ADDLABCONFIGS = `${CEC}/classrooms/add-lab-configs`;
export const CLASSROOMS_REMOVELABCONFIGS = `${CEC}/classrooms/remove-lab-configs`;
export const CLASSROOMS_ADDSTUDENTS = `${CEC}/classrooms/add-students`;
export const CLASSROOMS_REMOVESTUDENTS = `${CEC}/classrooms/remove-students`;
export const CLASSROOMS_RESTARTBOOKING = `${CEC}/classrooms/restart-booking`;
export const CLASSROOMS_SENDSTUDENTEMAILS = `${CEC}/classrooms/send-student-emails`;
export const CLASSROOMS_GLOBALACTIONS_REQUEST = `${CEC}/classrooms/global-actions/request`;
export const CLASSROOMS_GLOBALACTIONS = `${CEC}/classrooms/global-actions`;
export const CLASSROOMS_GLOBALACTIONS_ADMIN = `${CEC}/classrooms/global-actions/admin`;
export const CLASSROOMS_GLOBALACTIONS_INVENTORY = `${CEC}/classrooms/global-actions/inventory`;
export const CLASSROOMS_GLOBALACTIONS_INVENTORY_ADMIN = `${CEC}/classrooms/global-actions/inventory/admin`;
export const CLASSROOMS_GLOBALACTIONSSTATUSES = `${CEC}/classrooms/global-actions-statuses`;
export const CLASSROOMS_GLOBALACTIONSSTATUSES_ADMIN = `${CEC}/classrooms/global-actions-statuses/admin`;

// File Upload
export const FILEUPLOADS_FILES = `${CEC}/file-uploads/files`;
export const FILEUPLOADS_UPLOADTYPE = `${CEC}/file-uploads/upload-type`;
export const FILEUPLOADS_UPLOADTYPESLIST = `${CEC}/file-uploads/upload-types-list`;
export const FILEUPLOADS_DOMAIN = `${CEC}/file-uploads/domain`;
export const FILEUPLOADS = `${CEC}/file-uploads`;

// SSE Events
export const EVENTS_USERNOTIFICATIONEVENTS = `${CEC}/events/user-notification-events`;
export const EVENTS_ADMINLABSCHEDULE = `${CEC}/events/admin-lab-schedule`;
export const EVENTS_USERLABSCHEDULE = `${CEC}/events/user-lab-schedule`;
export const EVENTS_ADMINLABDETAILS = `${CEC}/events/admin-lab-details`;
export const EVENTS_USERLABDETAILS = `${CEC}/events/user-lab-details`;
export const EVENTS_USERLABACTIONS = `${CEC}/events/user-lab-actions`;
export const EVENTS_USERLABINTERACTIONS = `${CEC}/events/user-lab-interactions`;
export const EVENTS_INSTRUCTORCLASSROOMMANAGEMENT = `${CEC}/events/instructor-classroom-management`;
export const EVENTS_ADMINCLASSROOMMANAGEMENT = `${CEC}/events/admin-classroom-management`;
export const EVENTS_INSTRUCTORSTUDENTMANAGEMENT = `${CEC}/events/instructor-student-management`;
export const EVENTS_ADMINSTUDENTMANAGEMENT = `${CEC}/events/admin-student-management`;
export const EVENTS_INSTRUCTORDASHBOARD = `${CEC}/events/instructor-dashboard`;
export const EVENTS_ADMINDASHBOARD = `${CEC}/events/admin-dashboard`;
export const EVENTS_STUDENTLABAUDIT = `${CEC}/events/student-lab-audit`;

// Chat
export const CHAT_PLATFORMINFO = `${CEC}/chat/platform-info`;

/**
 * Services
 */
export const getRequest = (url, options) =>
  request(url, {
    method: 'GET',
    ...options,
  });

export const postRequest = (url, options) =>
  request(url, {
    method: 'POST',
    ...options,
  });

// Analytics
export const getAnalyticsPlatformInfo = () =>
  request(ANALYTICS_PLATFORMINFO, {
    method: 'GET',
  });

export const getAnalyticsEventsMatrix = () =>
  request(ANALYTICS_EVENTSMATRIX, {
    method: 'GET',
  });

export const getAnalyticsReportTypes = () =>
  request(ANALYTICS_REPORTTYPES, {
    method: 'GET',
  });

export const getAnalyticsVisitReport = (params) =>
  request(ANALYTICS_VISITREPORT, {
    method: 'GET',
    params,
  });

export const getAnalyticsLabsBooked = (params) =>
  request(ANALYTICS_LABSBOOKED, {
    method: 'GET',
    params,
  });

export const getAnalyticsAdminLabsBooked = (params) =>
  request(ANALYTICS_ADMIN_LABSBOOKED, {
    method: 'GET',
    params,
  });

export const getAnalyticsAdminLabsBookedCounts = (params) =>
  request(ANALYTICS_ADMIN_LABSBOOKEDCOUNTS, {
    method: 'GET',
    params,
  });

export const getAnalyticsAdminViewDocumentsCounts = (params) =>
  request(ANALYTICS_ADMIN_VIEWEDDOCUMENTSCOUNTS, {
    method: 'GET',
    params,
  });

export const getAnalyticsAdminWatchedVideosCounts = (params) =>
  request(ANALYTICS_ADMIN_WATCHEDVIDEOSCOUNTS, {
    method: 'GET',
    params,
  });

export const getAnalyticsAdminDocumentationVideoLinkCounts = (params) =>
  request(ANALYTICS_ADMIN_DOCUMENTATIONVIDEOLINKCOUNTS, {
    method: 'GET',
    params,
  });

export const getAnalyticsAdminWatchedDocumentationVideoCounts = (params) =>
  request(ANALYTICS_ADMIN_WATCHEDDOCUMENTATIONVIDEOCOUNTS, {
    method: 'GET',
    params,
  });

export const getAnalyticsEnrolledUsers = (params) =>
  request(ANALYTICS_ENROLLEDUSERS, {
    method: 'GET',
    params,
  });

export const getAnalyticsAdminEnrolledUsers = (params) =>
  request(ANALYTICS_ADMIN_ENROLLEDUSERS, {
    method: 'GET',
    params,
  });

export const getAnalyticsAdminAnalyticsPortalUrl = () =>
  request(ANALYTICS_ADMIN_ANALYTICSPORTALURL, {
    method: 'GET',
  });

export const patchAnalyticsNotificationEventOpenLab = (params) =>
  request(ANALYTICS_NOTIFICATIONEVENT_OPENLAB, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const patchAnalyticsNotificationEventWatchVideo = (params) =>
  request(ANALYTICS_NOTIFICATIONEVENT_WATCHVIDEO, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const patchAnalyticsNotificationEventReadDoc = (params) =>
  request(ANALYTICS_NOTIFICATIONEVENT_READDOC, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const getAnalyticsApiTrackerFieldLists = () =>
  request(ANALYTICS_APITRACKER_FIELDLISTS, {
    method: 'GET',
  });

export const getAnalyticsApiTrackerArchiveCounts = (params) =>
  request(ANALYTICS_APITRACKER_ARCHIVE_COUNTS, {
    method: 'GET',
    params,
  });

export const getAnalyticsApiTrackerCounts = (params) =>
  request(ANALYTICS_APITRACKER_COUNTS, {
    method: 'GET',
    params,
  });

// Auth
export const patchAuthSwitchDomain = (params) =>
  request(AUTH_SWITCHDOMAIN, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const patchAuthLogout = (params) =>
  request(AUTH_LOGOUT, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const getAuthProfile = () =>
  request(AUTH_PROFILE, {
    method: 'GET',
  });

// Users
export const getUsers = (params) =>
  request(USERS + '/paginated', {
    method: 'GET',
    params,
  });

export const getUsersActive = (params) =>
  request(USERS_ACTIVE, {
    method: 'GET',
    params,
  });

export const postUsers = (params) =>
  request(USERS, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const deleteUsers = (params) =>
  request(USERS, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const getUsersAdminUsernameList = () =>
  request(USERS_ADMIN_USERNAMELIST, {
    method: 'GET',
  });

export const getUsersProfile = () =>
  request(USERS_PROFILE, {
    method: 'GET',
  });

export const getUsersInstructorList = () =>
  request(USERS_INSTRUCTORLIST, {
    method: 'GET',
  });

export const patchUsersEulaVersion = (params) =>
  request(USERS_EULA_VERSION, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const getUsersAuthorizationToolbarIcons = () =>
  request(USERS_AUTHORIZATION_TOOLBARICONS, {
    method: 'GET',
  });

export const patchUsersSwitchDomain = (params) =>
  request(USERS_SWITCHDOMAIN, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const patchUsersRole = (params) =>
  request(USERS_ROLE, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const patchUsersRoles = (params) =>
  request(USERS_ROLES, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const patchUsersMemberDomains = (params) =>
  request(USERS_MEMBERDOMAINS, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const patchUsersGenerateApiKey = (params) =>
  request(USERS_GENERATEAPIKEY, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const getUsersReportIssuesAdminListIssues = (params) =>
  request(USERS_REPORTISSUES_ADMIN_LISTISSUES, {
    method: 'GET',
    params,
  });

export const getUsersReportIssuesSupportedFileTypes = () =>
  request(USERS_REPORTISSUES_SUPPORTEDFILETYPES, {
    method: 'GET',
  });

export const postUsersReportIssues = (params) =>
  request(USERS_REPORTISSUES, {
    method: 'POST',
    body: formatFormData(params),
  });

export const patchUsersReportIssuesAdminSendResponse = (params) =>
  request(USERS_REPORTISSUES_ADMIN_SENDRESPONSE, {
    method: 'PATCH',
    body: formatFormData(params),
  });

export const getUsersVideoNotificationList = () =>
  request(USERS_VIDEONOTIFICATIONLIST, {
    method: 'GET',
  });

// Content Management
export const getCategories = () =>
  request(CONTENTMANAGEMENT_CATEGORIES, {
    method: 'GET',
  });

export const getCategoriesAdmin = () =>
  request(CONTENTMANAGEMENT_CATEGORIES_ADMIN, {
    method: 'GET',
  });

export const getEditCategoriesAdmin = (params) =>
  request(CONTENTMANAGEMENT_CATEGORIES_ADMIN, {
    method: 'GET',
    params,
  });

export const getSubCategories = (params) =>
  request(CONTENTMANAGEMENT_SUBCATEGORIES, {
    method: 'GET',
    params,
  });

export const getSubCategoriesAdmin = () =>
  request(CONTENTMANAGEMENT_SUBCATEGORIES_ADMIN, {
    method: 'GET',
  });

export const postSubCategories = (params) =>
  request(CONTENTMANAGEMENT_SUBCATEGORIES, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const deleteSubCategories = (params) =>
  request(CONTENTMANAGEMENT_SUBCATEGORIES, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const getContents = (params) =>
  request(CONTENTMANAGEMENT_CONTENTS, {
    method: 'GET',
    params,
  });

export const getContentsAdmin = (params) =>
  request(CONTENTMANAGEMENT_CONTENTS_ADMIN, {
    method: 'GET',
    params,
  });

export const postContents = (params) =>
  request(CONTENTMANAGEMENT_CONTENTS, {
    method: 'POST',
    body: formatFormData(params),
  });

export const deleteContents = (params) =>
  request(CONTENTMANAGEMENT_CONTENTS, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const putContents = (params) =>
  request(CONTENTMANAGEMENT_CONTENTS, {
    method: 'PUT',
    body: formatFormData(params),
  });

export const getMCPDocs = () =>
  request(CONTENTMANAGEMENT_MCPDOCS, {
    method: 'GET',
  });

export const getMCPDocsAdmin = () =>
  request(CONTENTMANAGEMENT_MCPDOCS_ADMIN, {
    method: 'GET',
  });

export const postMCPDocs = (params) =>
  request(CONTENTMANAGEMENT_MCPDOCS, {
    method: 'POST',
    body: formatFormData(params),
  });

export const deleteMCPDocs = (params) =>
  request(CONTENTMANAGEMENT_MCPDOCS, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

// Email
export const postEmailSendNotifyToBCCList = (params) =>
  request(EMAIL_SENDNOTIFYTOBCCLIST, {
    method: 'POST',
    body: formatFormData(params),
  });

// Eulas
export const getEulas = () =>
  request(SITEMANAGEMENT_EULAS, {
    method: 'GET',
  });

export const postEulas = (params) =>
  request(SITEMANAGEMENT_EULAS, {
    method: 'POST',
    body: formatFormData(params),
  });

export const getEulasLatest = () =>
  request(SITEMANAGEMENT_EULAS_LATEST, {
    method: 'GET',
  });

// File Upload Eulas
export const getFileUploadEulas = () =>
  request(SITEMANAGEMENT_FILEUPLOADEULAS, {
    method: 'GET',
  });

export const postFileUploadEulas = (params) =>
  request(SITEMANAGEMENT_FILEUPLOADEULAS, {
    method: 'POST',
    body: formatFormData(params),
  });

export const getFileUploadEulasLatest = () =>
  request(SITEMANAGEMENT_FILEUPLOADEULAS_LATEST, {
    method: 'GET',
  });

// Portal Events
export const getPortalEvents = (params) =>
  request(SITEMANAGEMENT_PORTALEVENTS, {
    method: 'GET',
    params,
  });

export const postPortalEvents = (params) =>
  request(SITEMANAGEMENT_PORTALEVENTS, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const patchPortalEvents = (params) =>
  request(SITEMANAGEMENT_PORTALEVENTS, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const deletePortalEvents = (params) =>
  request(SITEMANAGEMENT_PORTALEVENTS, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const getPortalEventsActive = () =>
  request(SITEMANAGEMENT_PORTALEVENTS_ACTIVE, {
    method: 'GET',
  });

// Landing Page Banners
export const getLandingPage = (params) =>
  request(SITEMANAGEMENT_LANDINGPAGE, {
    method: 'GET',
    params,
  });

export const postLandingPage = (params) =>
  request(SITEMANAGEMENT_LANDINGPAGE, {
    method: 'POST',
    body: formatFormData(params),
  });

export const patchLandingPage = (params) =>
  request(SITEMANAGEMENT_LANDINGPAGE, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const deleteLandingPage = (params) =>
  request(SITEMANAGEMENT_LANDINGPAGE, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const getLandingPageActive = () =>
  request(SITEMANAGEMENT_LANDINGPAGE_ACTIVE, {
    method: 'GET',
  });

// Swagger Files
export const getSwaggerFiles = () =>
  request(SITEMANAGEMENT_SWAGGERFILES, {
    method: 'GET',
  });

export const postSwaggerFiles = (params) =>
  request(SITEMANAGEMENT_SWAGGERFILES, {
    method: 'POST',
    body: formatFormData(params),
  });

export const deleteSwaggerFiles = (params) =>
  request(SITEMANAGEMENT_SWAGGERFILES, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

// Device Images
export const getDeviceImages = () =>
  request(SITEMANAGEMENT_DEVICEIMAGES, {
    method: 'GET',
  });

export const postDeviceImages = (params) =>
  request(SITEMANAGEMENT_DEVICEIMAGES, {
    method: 'POST',
    body: formatFormData(params),
  });

export const deleteDeviceImages = (params) =>
  request(SITEMANAGEMENT_DEVICEIMAGES, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

// Domain Blacklist
export const getDomainBlacklist = (params) =>
  request(SECURITY_DOMAINBLACKLIST, {
    method: 'GET',
    params,
  });

export const postDomainBlacklist = (params) =>
  request(SECURITY_DOMAINBLACKLIST, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const deleteDomainBlacklist = (params) =>
  request(SECURITY_DOMAINBLACKLIST, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

// User Permissions
export const getUserPermissions = (params) =>
  request(SECURITY_USERPERMISSIONS, {
    method: 'GET',
    params,
  });

export const postUserPermissions = (params) =>
  request(SECURITY_USERPERMISSIONS, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const deleteUserPermissions = (params) =>
  request(SECURITY_USERPERMISSIONS, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

// Lab Domains
export const getLabDomains = (params) =>
  request(LABDOMAINS, {
    method: 'GET',
    params,
  });

export const postLabDomains = (params) =>
  request(LABDOMAINS, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const patchLabDomains = (params) =>
  request(LABDOMAINS, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const deleteLabDomains = (params) =>
  request(LABDOMAINS, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

// User Lab Domains
export const getUserLabDomains = (params, pathname) =>
  request(USERLABDOMAINS, {
    method: 'GET',
    params,
    pathname,
  });

export const postUserLabDomains = (params) =>
  request(USERLABDOMAINS, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const patchUserLabDomains = (params) =>
  request(USERLABDOMAINS, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const deleteUserLabDomains = (params) =>
  request(USERLABDOMAINS, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

// Labs
export const getLabsBookings = (params) =>
  request(LABS_BOOKINGS, {
    method: 'GET',
    params,
  });

export const getLabsBookingsAdmin = (params) =>
  request(LABS_BOOKINGS_ADMIN, {
    method: 'GET',
    params,
  });

export const patchLabsBookingsAudit = (params) =>
  request(LABS_BOOKINGS_AUDIT, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const getLabsBookingsAudit = (params, pathname) =>
  request(LABS_BOOKINGS_AUDIT, {
    method: 'GET',
    params,
    pathname,
  });

export const patchLabsBookingsCancel = (params) =>
  request(LABS_BOOKINGS_CANCEL, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const patchLabsBookingsAdminCancel = (params) =>
  request(LABS_BOOKINGS_ADMIN_CANCEL, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const patchLabsBookingsMaintenanceMode = (params) =>
  request(LABS_BOOKINGS_MAINTENANCEMODE, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const patchLabsBookingsAdminUpdateBooking = (params) =>
  request(LABS_BOOKINGS_ADMIN_UPDATEBOOKING, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const deleteLabsBookingsScheduled = (params) =>
  request(LABS_BOOKINGS_SCHEDULED, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const deleteLabsBookingsScheduledAdmin = (params) =>
  request(LABS_BOOKINGS_SCHEDULED_ADMIN, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const getLabsNetworkconfigTypesAdmin = (params) =>
  request(LABS_NETWORKCONFIGTYPES_ADMIN, {
    method: 'GET',
    params,
  });

export const getLabsVirtualdevices = (params, pathname) =>
  request(LABS_VIRTUALDEVICES, {
    method: 'GET',
    params,
    pathname,
  });

export const getLabsVirtualdevicesAdmin = (params) =>
  request(LABS_VIRTUALDEVICES_ADMIN, {
    method: 'GET',
    params,
  });

export const getLabsActions = (params, pathname) =>
  request(LABS_ACTIONS, {
    method: 'GET',
    params,
    pathname,
  });

export const getLabsActionsAdmin = (params) =>
  request(LABS_ACTIONS_ADMIN, {
    method: 'GET',
    params,
  });

export const postLabsActionsRequest = (params) =>
  request(LABS_ACTIONS_REQUEST, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const getLabsActionsInventory = (params, pathname) =>
  request(LABS_ACTIONS_INVENTORY, {
    method: 'GET',
    params,
    pathname,
  });

export const getLabsActionsInventoryAdmin = (params) =>
  request(LABS_ACTIONS_INVENTORY_ADMIN, {
    method: 'GET',
    params,
  });

export const getLabsVirtualdevicesInteractions = (params, pathname) =>
  request(LABS_VIRTUALDEVICES_INTERACTIONS, {
    method: 'GET',
    params,
    pathname,
  });

export const getLabsVirtualdevicesInteractionsAdmin = (params) =>
  request(LABS_VIRTUALDEVICES_INTERACTIONS_ADMIN, {
    method: 'GET',
    params,
  });

// Media
export const getMediaVideoPortalUrl = () =>
  request(MEDIA_VIDEOPORTALURL, {
    method: 'GET',
  });

export const getMediaAdminVideoPortalUrl = () =>
  request(MEDIA_ADMIN_VIDEOPORTALURL, {
    method: 'GET',
  });

export const getMediaMediaList = (params) =>
  request(MEDIA_MEDIALIST, {
    method: 'GET',
    params,
  });

export const getMediaCategoryList = (params) =>
  request(MEDIA_CATEGORYLIST, {
    method: 'GET',
    params,
  });

export const getMediaTagList = (params) =>
  request(MEDIA_TAGLIST, {
    method: 'GET',
    params,
  });

export const getMediaFixedFilters = () =>
  request(MEDIA_FIXEDDFILTERS, {
    method: 'GET',
  });

export const getMediaDocumentationMediaList = (params, pathname) =>
  request(MEDIA_DOCUMENTATION_MEDIALIST, {
    method: 'GET',
    params,
    pathname,
  });

export const getMediaDocumentationProductTypes = () =>
  request(MEDIA_DOCUMENTATION_DOCUMENTATIONPRODUCTTYPES, {
    method: 'GET',
  });

export const getMediaDocumentationMediaListDocumentationReport = (params) =>
  request(MEDIA_DOCUMENTATION_MEDIALIST_DOCUMENTATIONREPORT, {
    method: 'GET',
    params,
  });

export const postMediaDocumentationDocumentationLinks = (params) =>
  request(MEDIA_DOCUMENTATION_DOCUMENTATIONLINKS, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const patchMediaDocumentationDocumentationLinks = (params) =>
  request(MEDIA_DOCUMENTATION_DOCUMENTATIONLINKS, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const deleteMediaDocumentationDocumentationLinks = (params) =>
  request(MEDIA_DOCUMENTATION_DOCUMENTATIONLINKS, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const putMediaVideoNotificationsSubscribe = (params) =>
  request(MEDIA_VIDEONOTIFICATIONS_SUBSCRIBE, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const putMediaVideoNotificationsUnsubscribe = (params) =>
  request(MEDIA_VIDEONOTIFICATIONS_UNSUBSCRIBE, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const getMediaVideoNotificationsList = (params) =>
  request(MEDIA_VIDEONOTIFICATIONS_LIST, {
    method: 'GET',
    params,
  });

export const putMediaVideoNotificationsSubscribeUser = (params) =>
  request(MEDIA_VIDEONOTIFICATIONS_SUBSCRIBEUSER, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const putMediaVideoNotificationsUnsubscribeUser = (params) =>
  request(MEDIA_VIDEONOTIFICATIONS_UNSUBSCRIBEUSER, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

// Proxy
export const postProxyComputePath = (params) =>
  request(PROXY_COMPUTEPATH, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

// Schedule Lab
export const getScheduleGetSchedule = (params) =>
  request(SCHEDULE_GETSCHEDULE, {
    method: 'GET',
    params,
  });

export const getScheduleGetScheduleForWindow = (params) =>
  request(SCHEDULE_GETSCHEDULEFORWINDOW, {
    method: 'GET',
    params,
  });

export const putScheduleVerifyBooking = (params) =>
  request(SCHEDULE_VERIFYBOOKING, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const postScheduleScheduleLab = (params) =>
  request(SCHEDULE_SCHEDULELAB, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const postScheduleScheduleLabAdmin = (params) =>
  request(SCHEDULE_SCHEDULELAB_ADMIN, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

// Classrooms
export const getClassrooms = (params) =>
  request(CLASSROOMS, {
    method: 'GET',
    params,
  });

export const getClassroomsAdmin = (params) =>
  request(CLASSROOMS_ADMIN, {
    method: 'GET',
    params,
  });

export const getClassroomsActive = (params) =>
  request(CLASSROOMS_ACTIVE, {
    method: 'GET',
    params,
  });

export const getClassroomsActiveAdmin = (params) =>
  request(CLASSROOMS_ACTIVE_ADMIN, {
    method: 'GET',
    params,
  });

export const postClassrooms = (params) =>
  request(CLASSROOMS, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const postClassroomsAdmin = (params) =>
  request(CLASSROOMS_ADMIN, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const patchClassroomsCancel = (params) =>
  request(CLASSROOMS_CANCEL, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const patchClassroomsAdminCancel = (params) =>
  request(CLASSROOMS_ADMIN_CANCEL, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const deleteClassroomsScheduled = (params) =>
  request(CLASSROOMS_SCHEDULED, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const deleteClassroomsScheduledAdmin = (params) =>
  request(CLASSROOMS_SCHEDULED_ADMIN, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const patchClassroomAddLabConfigs = (params) =>
  request(CLASSROOMS_ADDLABCONFIGS, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const patchClassroomRemoveLabConfigs = (params) =>
  request(CLASSROOMS_REMOVELABCONFIGS, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const patchClassroomAddStudents = (params) =>
  request(CLASSROOMS_ADDSTUDENTS, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const patchClassroomRemoveStudents = (params) =>
  request(CLASSROOMS_REMOVESTUDENTS, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const patchClassroomRestartBooking = (params) =>
  request(CLASSROOMS_RESTARTBOOKING, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const patchClassroomSendStudentEmails = (params) =>
  request(CLASSROOMS_SENDSTUDENTEMAILS, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const getClassroomsGlobalActions = (params, pathname) =>
  request(CLASSROOMS_GLOBALACTIONS, {
    method: 'GET',
    params,
    pathname,
  });

export const getClassroomsGlobalActionsAdmin = (params) =>
  request(CLASSROOMS_GLOBALACTIONS_ADMIN, {
    method: 'GET',
    params,
  });

export const postClassroomsGlobalActionsRequest = (params) =>
  request(CLASSROOMS_GLOBALACTIONS_REQUEST, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const getClassroomsGlobalActionsInventory = (params, pathname) =>
  request(CLASSROOMS_GLOBALACTIONS_INVENTORY, {
    method: 'GET',
    params,
    pathname,
  });

export const getClassroomsGlobalActionsInventoryAdmin = (params) =>
  request(CLASSROOMS_GLOBALACTIONS_INVENTORY_ADMIN, {
    method: 'GET',
    params,
  });

export const getClassroomsGlobalActionsStatuses = (params, pathname) =>
  request(CLASSROOMS_GLOBALACTIONSSTATUSES, {
    method: 'GET',
    params,
    pathname,
  });

export const getClassroomsGlobalActionsStatusesAdmin = (params) =>
  request(CLASSROOMS_GLOBALACTIONSSTATUSES_ADMIN, {
    method: 'GET',
    params,
  });

// File Upload
export const getFileUploadsFiles = (params) =>
  request(FILEUPLOADS_FILES, {
    method: 'GET',
    params,
  });

export const getFileUploadsUploadType = (params) =>
  request(FILEUPLOADS_UPLOADTYPE, {
    method: 'GET',
    params,
  });

export const getFileUploadsUploadTypesList = () =>
  request(FILEUPLOADS_UPLOADTYPESLIST, {
    method: 'GET',
  });

export const postFileUploads = (params, pathname) =>
  request(FILEUPLOADS, {
    method: 'POST',
    body: formatFormData(params),
    pathname,
  });

export const deleteFileUploads = (params) =>
  request(FILEUPLOADS, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

export const deleteFileUploadsDomain = (params) =>
  request(FILEUPLOADS_DOMAIN, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

// Chat
export const getChatPlatformInfo = () =>
  request(CHAT_PLATFORMINFO, {
    method: 'GET',
  });
