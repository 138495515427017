import React from 'react';
import moment from 'moment';
import { connect } from 'dva';
import { Layout, Form, Input, Table, Button } from 'antd';
import debounce from 'lodash/debounce';
import AppIcon from '@/components/app-icon';
import InfoBar from '@/components/info-bar';
import SidePanel from '@/components/side-panel';
import { DATE_TIME_FORMAT } from '@/constants';
import VideoNotificationDetails from './video-notification-details';

import './index.less';

export const ROW_KEY = 'username';

const notificationHistoryColumns = [
  {
    title: 'Send Date',
    dataIndex: 'sendDate',
    key: 'sendDate',
    render: (value) => value && moment(value).format(DATE_TIME_FORMAT),
  },
  Table.EXPAND_COLUMN,
  {
    title: 'New Videos',
    dataIndex: 'newVideos',
    key: 'newVideos',
    render: (value) => value.length,
  },
];

const newVideoColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Categories',
    dataIndex: 'categories',
    key: 'categories',
  },
  {
    title: 'Tags',
    dataIndex: 'tags',
    key: 'tags',
  },
];

class MediaVideoNotification extends React.Component {
  constructor(props) {
    super(props);

    props.dispatch({ type: 'videoNotificationModel/getMediaVideoNotificationsList' });

    this.state = {
      showModal: false,
    };
  }

  columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value) => value && moment(value).format(DATE_TIME_FORMAT),
    },
    {
      title: 'Last Subscribe Change',
      dataIndex: 'lastSubscribeChange',
      key: 'lastSubscribeChange',
      render: (value) => value && moment(value).format(DATE_TIME_FORMAT),
    },
    Table.EXPAND_COLUMN,
    {
      title: 'Notification History',
      dataIndex: 'notificationHistory',
      key: 'notificationHistory',
      render: (value) => value.length,
    },
    {
      title: 'Subscribed',
      dataIndex: 'subscribed',
      key: 'subscribed',
      render: (value, record) =>
        value ? (
          <Button
            ghost
            danger
            onClick={() => {
              this.props.dispatch({
                type: 'videoNotificationModel/putMediaVideoNotificationsUnsubscribeUser',
                payload: {
                  username: record.username,
                  subscribed: value,
                },
              });
            }}
          >
            Unsubscribe
          </Button>
        ) : (
          <Button
            type="primary"
            className="ant-btn-green"
            onClick={() => {
              this.props.dispatch({
                type: 'videoNotificationModel/putMediaVideoNotificationsSubscribeUser',
                payload: {
                  username: record.username,
                  subscribed: value,
                },
              });
            }}
          >
            Subscribe
          </Button>
        ),
    },
  ];

  showVideoNotificationDetails = () => {
    this.setState({ showModal: true });
  };

  hideVideoNotificationDetails = () => {
    this.setState({ showModal: false });
  };

  renderSider = () => {
    const {
      dispatch,
      videoNotificationModel: { refineParams },
    } = this.props;

    return (
      <SidePanel type="filter">
        <Form
          layout="vertical"
          initialValues={refineParams}
          onValuesChange={debounce((_, values) => {
            dispatch({
              type: 'videoNotificationModel/getMediaVideoNotificationsList',
              payload: values,
            });
            dispatch({
              type: 'videoNotificationModel/setState',
              payload: {
                refineParams: values,
              },
            });
          }, 500)}
        >
          <Form.Item
            name="username"
            label="Username"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="subscribed"
            label="Subscribed"
          >
            <Input />
          </Form.Item>
        </Form>
      </SidePanel>
    );
  };

  render() {
    const {
      videoNotificationModel: { loading, videoNotificationList },
      dispatch,
    } = this.props;
    const { showModal } = this.state;

    return (
      <div>
        <InfoBar
          icon="MCP_app_menu_Problem_analysis"
          color="#0271bc"
          title="Video Notifications"
          subtitle="Manage video notifications"
          actions={[
            {
              label: 'Subscribe',
              icon: <AppIcon name="email" />,
              onClick: () => {
                this.showVideoNotificationDetails();
              },
            },
          ]}
        />
        <Layout>
          {this.renderSider()}
          <Layout.Content className="page">
            <div className="table-container">
              <Table
                className="table-row-expand-no-border"
                bordered
                size="small"
                rowKey={ROW_KEY}
                loading={loading}
                pagination={false}
                dataSource={videoNotificationList}
                columns={this.columns}
                expandable={{
                  rowExpandable: (record) =>
                    record.notificationHistory && record.notificationHistory.length > 0,
                  expandedRowRender: (record) => (
                    <Table
                      className="inner-table"
                      size="small"
                      rowKey="sendDate"
                      pagination={false}
                      dataSource={record.notificationHistory}
                      columns={notificationHistoryColumns}
                      expandable={{
                        rowExpandable: (record) => record.newVideos && record.newVideos.length > 0,
                        expandedRowRender: (record) => (
                          <Table
                            className="inner-table"
                            size="small"
                            rowKey="id"
                            pagination={false}
                            dataSource={record.newVideos}
                            columns={newVideoColumns}
                          />
                        ),
                      }}
                    />
                  ),
                }}
              />
            </div>
          </Layout.Content>
        </Layout>
        {showModal && (
          <VideoNotificationDetails
            videoNotificationList={videoNotificationList}
            onCancel={this.hideVideoNotificationDetails}
            dispatch={dispatch}
          />
        )}
      </div>
    );
  }
}

export default connect(({ videoNotificationModel }) => ({
  videoNotificationModel,
}))(MediaVideoNotification);
