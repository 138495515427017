import { message } from 'antd';
import {
  putMediaVideoNotificationsSubscribe,
  putMediaVideoNotificationsUnsubscribe,
  getMediaVideoNotificationsList,
  putMediaVideoNotificationsSubscribeUser,
  putMediaVideoNotificationsUnsubscribeUser,
} from '@/services';

const model = {
  namespace: 'videoNotificationModel',
  state: {
    videoNotificationList: [],
    subscribed: false,
  },
  subscriptions: {},
  effects: {
    *putMediaVideoNotificationsSubscribe(_, { call, put, select }) {
      try {
        yield call(putMediaVideoNotificationsSubscribe);

        const { profile } = yield select(({ userModel }) => ({
          profile: userModel.profile,
        }));

        yield put({
          type: 'userModel/setState',
          payload: {
            profile: {
              ...profile,
              subscribedToVideoNotifications: true,
            },
          },
        });

        yield message.success('Subscribed!');
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *putMediaVideoNotificationsUnsubscribe(_, { call, put, select }) {
      try {
        yield call(putMediaVideoNotificationsUnsubscribe);

        const { profile } = yield select(({ userModel }) => ({
          profile: userModel.profile,
        }));

        yield put({
          type: 'userModel/setState',
          payload: {
            profile: {
              ...profile,
              subscribedToVideoNotifications: false,
            },
          },
        });
        yield message.success('Unsubscribed!');
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *getMediaVideoNotificationsList({ payload }, { call, put, select }) {
      try {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'videoNotificationModel',
            loading: true,
          },
        });

        const { refineParams } = yield select(({ videoNotificationModel }) => ({
          refineParams: videoNotificationModel.refineParams,
        }));

        const data = yield call(getMediaVideoNotificationsList, payload || refineParams);

        yield put({
          type: 'setState',
          payload: {
            videoNotificationList: data,
          },
        });
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      } finally {
        yield put({
          type: 'appModel/setTableLoading',
          payload: {
            modelName: 'videoNotificationModel',
            loading: false,
          },
        });
      }
    },
    *putMediaVideoNotificationsSubscribeUser({ payload, callback }, { call, put }) {
      try {
        yield call(putMediaVideoNotificationsSubscribeUser, payload);

        yield put({ type: 'getMediaVideoNotificationsList' });

        yield message.success('Subscribed!');
        callback && callback();
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
    *putMediaVideoNotificationsUnsubscribeUser({ payload, callback }, { call, put }) {
      try {
        yield call(putMediaVideoNotificationsUnsubscribeUser, payload);

        yield put({ type: 'getMediaVideoNotificationsList' });

        yield message.success('Unsubscribed!');
        callback && callback();
      } catch (e) {
        yield put({ type: 'appModel/handleError', payload: e });
      }
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default model;
