import React from 'react';
import { connect } from 'dva';
import { Router, Route, Redirect, Switch } from 'dva/router';
import { Layout, Spin } from 'antd';
import AppBar from '@/components/app-bar';
import Home from '@/pages/home';
import Error from '@/pages/error';
import Video from '@/pages/video';
import DocVideo from '@/pages/doc-video';
import DocLabContent from '@/pages/doc-lab-content';
import DocMCP from '@/pages/doc-mcp';
import AdminContent from '@/pages/admin-content';
import AdminSubCategory from './pages/admin-sub-category';
import AdminDocMCP from '@/pages/admin-doc-mcp';
import AdminActiveSession from '@/pages/admin-active-session';
import AdminUserAccount from '@/pages/admin-user-account';
import AdminPermission from '@/pages/admin-permission';
import AdminBlacklist from '@/pages/admin-blacklist';
import AdminEula from '@/pages/admin-eula';
import AdminFileUploadEula from '@/pages/admin-file-upload-eula';
import AdminEvent from '@/pages/admin-event';
import AdminLandingPage from '@/pages/admin-landing-page';
import AdminSwaggerFile from '@/pages/admin-swagger-file';
import AdminDeviceImage from '@/pages/admin-device-image';
import LabSchedule from '@/pages/lab-schedule';
import LabUserFile from '@/pages/lab-user-file';
import LabInteraction from '@/pages/lab-interaction';
import LabTerminal from '@/pages/lab-terminal';
import LabCraft from '@/pages/lab-craft';
import User from '@/pages/user';
import AdminDomain from '@/pages/admin-domain';
import AdminUserDomain from '@/pages/admin-user-domain';
import AdminSchedule from '@/pages/admin-schedule';
import AdminReportIssue from '@/pages/admin-report-issue';
import InstructorClassroom from '@/pages/instructor-classroom';
import InstructorStudent from '@/pages/instructor-student';
import InstructorDashboard from '@/pages/instructor-dashboard';
import AnalyticsGenerateReport from '@/pages/analytics-generate-report';
import MediaDocVideo from '@/pages/media-doc-video';
import MediaVideoNotification from '@/pages/media-video-notification';
import Footer from '@/components/footer';
import { CEC, ROUTES } from '@/constants';

const getComponent = (Component) => {
  const Comp = (props) => {
    const {
      appModel: { validCookie },
    } = props;

    return validCookie ? (
      <Component {...props} />
    ) : (
      <Spin
        tip="Loading"
        size="large"
      >
        <Layout />
      </Spin>
    );
  };

  return connect(({ appModel }) => ({ appModel }))(Comp);
};

const RouterConfig = ({
  history,
  app: {
    _store: { dispatch },
  },
}) => {
  dispatch({ type: 'appModel/init' });

  return (
    <Router history={history}>
      <div id="body">
        <AppBar />
        <Switch>
          <Route
            exact
            path={CEC}
            component={Home}
          />
          <Route
            path={ROUTES.HOME}
            component={Home}
          />
          <Route
            path={ROUTES.ERROR}
            component={Error}
          />
          <Route
            path={`${ROUTES.DOC_VIDEO}/:referenceId`}
            component={DocVideo}
          />
          {/* Lab Docs */}
          <Route
            path={ROUTES.DOCLAB_CONTENT}
            component={getComponent(DocLabContent)}
          />
          {/* MCP Docs */}
          <Route
            path={ROUTES.DOCMCP}
            component={getComponent(DocMCP)}
          />
          {/* Videos */}
          <Route
            path={ROUTES.VIDEO}
            component={getComponent(Video)}
          />
          {/* Admin - Content Management */}
          <Route
            path={ROUTES.ADMIN_CONTENT}
            component={getComponent(AdminContent)}
          />
          <Route
            path={ROUTES.ADMIN_SUBCATEGORY}
            component={getComponent(AdminSubCategory)}
          />
          <Route
            path={ROUTES.ADMIN_DOCMCP}
            component={getComponent(AdminDocMCP)}
          />
          {/* Admin - Security */}
          <Route
            path={ROUTES.ADMIN_ACTIVESESSION}
            component={getComponent(AdminActiveSession)}
          />
          <Route
            path={ROUTES.ADMIN_USERACCOUNT}
            component={getComponent(AdminUserAccount)}
          />
          <Route
            path={ROUTES.ADMIN_PERMISSION}
            component={getComponent(AdminPermission)}
          />
          <Route
            path={ROUTES.ADMIN_BLACKLIST}
            component={getComponent(AdminBlacklist)}
          />
          {/* Admin - Domain Management */}
          <Route
            path={ROUTES.ADMIN_DOMAIN}
            component={getComponent(AdminDomain)}
          />
          <Route
            path={ROUTES.ADMIN_USERDOMAIN}
            component={getComponent(AdminUserDomain)}
          />
          {/* Admin - Site Management */}
          <Route
            path={ROUTES.ADMIN_EULA}
            component={getComponent(AdminEula)}
          />
          <Route
            path={ROUTES.ADMIN_FILEUPLOADEULA}
            component={getComponent(AdminFileUploadEula)}
          />
          <Route
            path={ROUTES.ADMIN_EVENT}
            component={getComponent(AdminEvent)}
          />
          <Route
            path={ROUTES.ADMIN_LANDINGPAGE}
            component={getComponent(AdminLandingPage)}
          />
          <Route
            path={ROUTES.ADMIN_SWAGGERFILE}
            component={getComponent(AdminSwaggerFile)}
          />
          <Route
            path={ROUTES.ADMIN_DEVICEIMAGE}
            component={getComponent(AdminDeviceImage)}
          />
          {/* Admin - Schedule Management */}
          <Route
            path={ROUTES.ADMIN_SCHEDULE}
            component={getComponent(AdminSchedule)}
          />
          {/* Admin - Report Issue Management */}
          <Route
            path={ROUTES.ADMIN_REPORTISSUE}
            component={getComponent(AdminReportIssue)}
          />
          {/* Lab */}
          <Route
            path={ROUTES.LAB_SCHEDULE}
            component={getComponent(LabSchedule)}
          />
          <Route
            path={ROUTES.LAB_USERFILE}
            component={getComponent(LabUserFile)}
          />
          <Route
            path={`${ROUTES.LAB_INTERACTION}/:bookingId`}
            component={getComponent(LabInteraction)}
          />
          <Route
            path={`${ROUTES.LAB_TERMINAL}/:webTermLink`}
            component={LabTerminal}
          />
          <Route
            path={ROUTES.LAB_CRAFT}
            component={getComponent(LabCraft)}
          />
          {/* Instructor - Classroom Management */}
          <Route
            path={ROUTES.INSTRUCTOR_CLASSROOM}
            component={getComponent(InstructorClassroom)}
          />
          {/* Instructor - Student Management */}
          <Route
            path={ROUTES.INSTRUCTOR_STUDENT}
            component={getComponent(InstructorStudent)}
          />
          {/* Instructor - Dashboard */}
          <Route
            path={ROUTES.INSTRUCTOR_DASHBOARD}
            component={getComponent(InstructorDashboard)}
          />
          {/* Analytics - Generate Report */}
          <Route
            path={ROUTES.ANALYTICS_GENERATE_REPORT}
            component={getComponent(AnalyticsGenerateReport)}
          />
          {/* Media - Documentation Video */}
          <Route
            path={ROUTES.MEDIA_DOC_VIDEO}
            component={getComponent(MediaDocVideo)}
          />
          {/* Media - Documentation Video */}
          <Route
            path={ROUTES.MEDIA_VIDEO_NOTIFICATION}
            component={getComponent(MediaVideoNotification)}
          />
          {/* User Profile */}
          <Route
            path={ROUTES.USER}
            component={getComponent(User)}
          />
          {/* todo: redirect */}
          <Redirect
            from={ROUTES.LAB}
            to={ROUTES.LAB_SCHEDULE}
          />
          <Redirect to={ROUTES.HOME} />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
};

export default RouterConfig;
